<script setup>
import Actualite from "~/src/models/Actualite.js";

const props = defineProps({
  post: {type: Object, default: () => ({})}
});

const actu = new Actualite(props.post);
</script>
<template>
  <div class="post flex flex-col rounded-lg shadow-lg overflow-hidden" :key="'actu-'+actu.id">
    <NuxtLink :to="`/actualites/${actu.slug}`">
      <div class="flex-shrink-0 post__image" v-if="actu.image">
        <img class="h-48 w-full object-cover" :src="actu.image.getSrcUrl('small')" :alt="actu.image.alternativeText" />
      </div>
      <div class="flex-shrink-0 post__image" v-else>
        <div class="h-48 w-full text-center flex justify-center items-center">
            News du {{ actu.date$.format('ddd D MMM YYYY') }}
        </div>
      </div>
    </NuxtLink>
    <div class="flex-1 bg-white p-6 flex flex-col justify-between">
      <div class="flex-1">
        <div class="text-sm font-medium text-indigo-600">
          <NuxtLink :to="{name: 'category', params: {slug: actu.category.slug}}" v-if="actu.category" class="hover:underline">
            {{ actu.category.name }}
          </NuxtLink>
          <div class="text-sm text-gray-500 float-right">
            <time :datetime="actu.datetime">
              {{ actu.date$.format('ddd D MMM YYYY') }}
            </time>
          </div>
        </div>
        <NuxtLink :to="`/actualites/${actu.slug}`" dsfto="{name: 'actualites', params: {slug: actu.slug}}" class="block mt-2 hover:no-underline">
          <p class="text-xl font-semibold text-gray-900">
            {{ actu.title }}
          </p>
          <p class="mt-3 text-base text-gray-500">
            {{ actu.summary }}
          </p>
        </NuxtLink>
      </div>
    </div>
  </div>
</template>
